//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CourseList',
    methods: {
      onItemClick(course_id, course_name) {
        this.$emit('selectVenue', course_id);
        this.courseDefaultLabel = course_name;
        this.selectedCourseId = course_id;
      }
    }, 
    created() {
      this.$http({
        method: 'get',
        url: 'courses'
      }).then((response) => {
        if (response.data['status'] == "success") {
          this.courses = response.data['body']['courses'];
          if (this.defaultCourseId) {
            this.courseDefaultLabel = this.courses[this.getDefaultCourseId]['course_name'];
            this.selectedCourseId = this.courses[this.getDefaultCourseId]['id'];
            this.showList = true;
          } else {
            this.courseDefaultLabel = this.courses[0]['course_name'];
            this.selectedCourseId = this.courses[0]['id'];
          }
        }
      })
    },
    data() {
      return {
          isCourseLoading: false,
          course: '',
          courses: [],
          selectedCourseId: null,
          courseDefaultLabel: 'Loading..',
      }
    },
    props: [
      'defaultCourseId',
      'showList'
    ],
    computed: {
      getDefaultCourseId() {
        return this.defaultCourseId - 1;
      }
    }
}
