//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import CourseList from '@/components/CourseList'
export default {
  name: "ProfileSetup",
  components: {
    CourseList
  },
  data() {
      return {
          step: 1,
          isCourseLoading: false,
          courses: [],
          selectedCourseId: 1,
          mobile_number: '',
          share_mobile_number: false,
          share_email_address: false,
          email_address: this.$store.state.user.email_address,
          nickname: '',
          handicap: 0.0,
          unhs: '',
          work_company: '', 
          work_position: '',
          share_work_info: false,
          profilePhoto: require('@/assets/default_profile.webp'),
          file: null,
          showPhotoPreviewer: false,
          isUnhsValid: true
      }
  },
  metaInfo(){
        return {
            title:'Setup Profile'
        }
    },
    methods: {
      previewImage() {
        this.profilePhoto = URL.createObjectURL(this.file);
        this.showPhotoPreviewer = true;
      },
      onItemClick (id, name) {
        this.selectedCourseId = id;
        this.courseDefaultLabel = name;
      },
      saveProfile() {
        let system = this;

        if (isNaN(this.unhs)) {
          this.step = 3;
          this.isUnhsValid = false;

          return;
        }

        if (this.unhs.length !== 6 && this.unhs.length > 0) {
          this.step = 3;
          this.isUnhsValid = false;

          return;
        }

        system.$q.loading.show();

        var formData = new FormData();
        formData.append('course_id', this.selectedCourseId);
        formData.append('mobile_number', this.mobile_number);
        formData.append('share_mobile_number', this.share_mobile_number ? 1 : 0);
        formData.append('share_email_address', this.share_email_address  ? 1 : 0);
        formData.append('share_work_info', this.share_work_info  ? 1 : 0);
        formData.append('nickname', this.nickname);
        formData.append('unhs', this.unhs);
        formData.append('handicap', this.handicap);
        formData.append('work_info', this.work_position);
        formData.append('work_company', this.work_company);
        if (this.file) {
          formData.append('profile_photo', this.file);
        }
        this.$q.loading.show({
          message: 'Saving your profile..'
        });
        this.$http(
          {
            method: 'post',
            url: 'profiles',
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
          }
        ).then((response) => {
          this.$q.loading.hide();
          if (response.data['status'] == "profile_created") {
            if (this.file) {
               this.payload = {
                'user_id': this.$store.state.user.id,
                'type': 'profile'
              }
              // Reloads the uploaded profile photo.
              this.$store.dispatch('profile/getProfilePhoto', this.payload)
                .then((response) =>{
                  if(response == 'success'){
                    this.$store.state.user.profile_photo = this.$store.state.profile.profile_photo
                  }
              });
            }
            localStorage.removeItem('is_setup_required');
            this.$q.dialog({
              title: 'Welcome to Golfbean!',
              message: "Golfbean is a community of Golfers made by Golfers. You can create groups, create or join events and participate on scorecards. There many more to discover on this growing platform."
            }).onOk(() => {
              this.$router.push({name: 'Profile', params: {id: this.$store.state.user.id}})
            });
          } else {
            this.$q.dialog({
              title: 'We encountered an error..',
              message: 'Sorry but we encountered an error while saving your profile. Please try again later.'
            });
          }
        })
        system.$q.loading.hide();
      }
    }
}
