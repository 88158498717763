import { render, staticRenderFns } from "./ProfileSetup.vue?vue&type=template&id=ce3521d8&scoped=true&"
import script from "./ProfileSetup.vue?vue&type=script&lang=js&"
export * from "./ProfileSetup.vue?vue&type=script&lang=js&"
import style0 from "./ProfileSetup.vue?vue&type=style&index=0&id=ce3521d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce3521d8",
  null
  
)

export default component.exports
import {QForm,QStepper,QStep,QSeparator,QInput,QCheckbox,QAvatar,QFile,QIcon,QStepperNavigation,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QForm,QStepper,QStep,QSeparator,QInput,QCheckbox,QAvatar,QFile,QIcon,QStepperNavigation,QBtn})
